<template>
  <div>
    <b-modal
      id="edit-product-modal"
      ref="edit-product-modal"
      title="แก้ไขอุปกรณ์"
      no-close-on-backdrop
      size="lg"
      hide-footer
    >
      <validation-observer ref="editProduct">
        <b-form>
          <!-- ชื่ออุปกรณ์ -->
          <b-form-group label="ชื่อในระบบ">
            <validation-provider
              #default="{ errors }"
              name="Product Name"
              rules="required"
            >
              <b-form-input
                v-model="form.name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ชื่อในเว็บ -->
          <b-form-group label="ชื่อในเว็บ">
            <validation-provider
              #default="{ errors }"
              name="Web Name"
              rules="required"
            >
              <b-form-input
                v-model="form.web_name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ชื่อในสัญญา -->
          <b-form-group label="ชื่อในสัญญา">
            <validation-provider
              #default="{ errors }"
              name="Lease Name"
              rules="required"
            >
              <b-form-input
                v-model="form.lease_name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Slug macrumors ok-->
          <b-form-group label="Slug">
            <validation-provider
              #default="{ errors }"
              name="slug"
              rules="required"
            >
              <!-- 027092800 delta -->
              <!-- 0869980009 ภาสกร -->

              <b-form-input
                v-model="form.slug"
                :state="errors.length > 0 ? false : null"
              />
              <!-- :value="webtitleToslug" -->
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Item Type -->
          <b-form-group label="Type">
            <validation-provider
              #default="{ errors }"
              name="item_type"
              rules="required"
            >
              <b-form-input
                v-model="form.item_type"
                :state="errors.length > 0 ? false : null"
                :value="webtitleToslug"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            eg. camera , lens , accessories
          </b-form-group>

          <!-- Brand -->
          <b-form-group label="ยี่ห้อ">
            ไม่ใช้แล้วเพราะเลนส์นอกค่ายใช้หลายยี่ห้อ
            <validation-provider
              #default="{ errors }"
              name="Brand"
              rules="required"
            >
              <b-form-input
                v-model="form.brand"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ประเภทอุปกรณ์ -->
          <h5 class="text-white">Category</h5>
          <b-form-select
            v-model="form.categories"
            :options="product_categories_options"
          />

          <!-- Tags -->
          <h5 class="text-white">Tags</h5>
          <b-form-tags v-model="form.tags" no-outer-focus>
            <template
              #default="{ tags, inputAttrs, inputHandlers, addTag, removeTag }"
            >
              <b-input-group aria-controls="my-custom-tags-list">
                <input
                  v-bind="inputAttrs"
                  placeholder="New tag - Press enter to add"
                  class="form-control"
                  v-on="inputHandlers"
                />
                <b-input-group-append>
                  <b-button variant="primary" @click="addTag()"> Add </b-button>
                </b-input-group-append>
              </b-input-group>
              <ul
                id="my-custom-tags-list"
                class="list-unstyled d-inline-flex flex-wrap mb-0"
                aria-live="polite"
                aria-atomic="false"
                aria-relevant="additions removals"
              >
                <!-- Always use the tag value as the :key, not the index! -->
                <!-- Otherwise screen readers will not read the tag
                        additions and removals correctly -->
                <b-card
                  v-for="tag in tags"
                  :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                  :key="tag"
                  tag="li"
                  class="shadow-none border mt-1 mr-1 mb-0"
                  body-class="py-1 pr-2 text-nowrap"
                >
                  <strong>{{ tag }}</strong>
                  <b-button
                    variant="link"
                    size="sm"
                    :aria-controls="`my-custom-tags-tag_${tag.replace(
                      /\s/g,
                      '_'
                    )}_`"
                    class="py-0"
                    @click="removeTag(tag)"
                  >
                    remove
                  </b-button>
                </b-card>
              </ul>
            </template>
          </b-form-tags>

          <!-- ค่าเช่า -->
          <b-form-group label="ค่าเช่า">
            <validation-provider
              #default="{ errors }"
              name="Rental Price"
              rules="required"
            >
              <b-form-input
                v-model="form.rental_price"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ยอดวางประกัน -->
          <b-form-group label="ยอดวางประกัน">
            <validation-provider
              #default="{ errors }"
              name="Deposit Price"
              rules="required"
            >
              <b-form-input
                v-model="form.deposit_1"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ยอดวางประกัน -->
          <b-form-group label="ยอดวางประกัน 2">
            <validation-provider #default="{ errors }" name="Deposit Price 2">
              <b-form-input v-model="form.deposit_2" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ยอดวางประกัน 3 -->
          <b-form-group label="ยอดวางประกัน 3">
            <validation-provider #default="{ errors }" name="Deposit Price 3">
              <b-form-input v-model="form.deposit_3" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ราคาในสัญญาเช่า -->
          <b-form-group label="ราคาในสัญญาเช่า">
            <validation-provider
              #default="{ errors }"
              name="Lease Price"
              rules="required"
            >
              <b-form-input
                v-model="form.lease_price"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- การจัดส่ง -->
          <b-form-group>
            <h5>การจัดส่ง</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.shipable"
                name="some-radios"
                :value="true"
              >
                ส่งได้
              </b-form-radio>
              <b-form-radio
                v-model="form.shipable"
                name="some-radios"
                :value="false"
              >
                ส่งไม่ได้
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- แสดงหน้าเว็บ -->
          <b-form-group>
            <h5>แสดงหน้าเว็บ</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.web_enable"
                name="some-radios2"
                :value="true"
              >
                แสดงหน้าเว็บ
              </b-form-radio>
              <b-form-radio
                v-model="form.web_enable"
                name="some-radios2"
                :value="false"
              >
                ไม่แสดงหน้าเว็บ
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- ไม่แสดงหน้าแรก -->
          <b-form-group>
            <h5>ไม่แสดงหน้าแรก</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.web_hide_new_product"
                name="some-radios9"
                :value="false"
              >
                ขึ้นหน้าแรกปกติ
              </b-form-radio>
              <b-form-radio
                v-model="form.web_hide_new_product"
                name="some-radios9"
                :value="true"
              >
                ไม่แสดง
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- เปิดให้เช่า /  ยกเลิกการให้เช่าแล้ว -->
          <b-form-group>
            <h5>แสดงใน admin</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.admin_enable"
                name="some-radios3"
                :value="true"
              >
                เปิดให้เช่า
              </b-form-radio>
              <b-form-radio
                v-model="form.admin_enable"
                name="some-radios3"
                :value="false"
              >
                ยกเลิกการให้เช่าแล้ว
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- การคิดค่าเช่า -->
          <b-form-group>
            <h5>การคิดค่าเช่า</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.flat_rate"
                name="some-radios4"
                :value="false"
              >
                ปกติ
              </b-form-radio>
              <b-form-radio
                v-model="form.flat_rate"
                name="some-radios4"
                :value="true"
              >
                ราคาเดียว
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- Is GoPro -->
          <b-form-group>
            <h5>GoProlineup</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.is_gopro"
                name="some-radios5"
                :value="true"
              >
                ใช่
              </b-form-radio>
              <b-form-radio
                v-model="form.is_gopro"
                name="some-radios5"
                :value="false"
              >
                ไม่
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- IS KIT -->
          <b-form-group>
            <h5>อุปกรณ์จัดชุด</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.is_kit"
                name="some-radios6"
                :value="true"
              >
                ใช่
              </b-form-radio>
              <b-form-radio
                v-model="form.is_kit"
                name="some-radios6"
                :value="false"
              >
                ไม่
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- เลือกฟรี 1 รายการ -->
          <b-form-group
            v-if="form.is_gopro && form.categories == 'GoPro Accessories'"
          >
            <h5>เลือกฟรี 1 รายการ</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.gopro_acc_free"
                name="some-radios7"
                :value="true"
              >
                เลือกฟรี 1 รายการ
              </b-form-radio>
              <b-form-radio
                v-model="form.gopro_acc_free"
                name="some-radios7"
                :value="false"
              >
                เช่าเพิ่มคิดเป็นครั้ง
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- is_third_party -->
          <b-form-group class="mb-2">
            <h5>is_third_party</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.is_third_party"
                name="some-radios8"
                :value="false"
              >
                original
              </b-form-radio>

              <b-form-radio
                v-model="form.is_third_party"
                name="some-radios8"
                :value="true"
              >
                นอกค่าย
              </b-form-radio>
            </div>
          </b-form-group>

          <hr />
          <!-- ปุ่มแก้ไขข้อมูล -->
          <div class="text-right my-1">
            <b-button
              variant="secondary"
              class="mr-1"
              @click="$refs['edit-product-modal'].hide()"
            >
              ยกเลิก
            </b-button>
            <b-button variant="success" @click.prevent="validationForm">
              แก้ไขข้อมูล
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BFormSelect,
  BFormTags,
  BFormTag,
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  BFormRadio,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ProductCategories from "@/assets/product_categories.json";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import * as axios from "axios";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    BModal,
    BButton,
    BFormRadio,
    ValidationObserver,
    ValidationProvider,
    ToastificationContent,
    BFormTags,
    BFormTag,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BCard,
  },
  props: ["product_id"],
  data() {
    return {
      form: {
        shipable: null,
        web_enable: null,
        admin_enable: null,
        flat_rate: null,
        is_gopro: null,
        slug: null,
        is_third_party: false,
      },
      product_categories_options: ProductCategories,
      value: [],
      // product_categories:ProductCategories,
    };
  },
  computed: {
    availableOptions() {
      return this.options.filter((opt) => this.value.indexOf(opt) === -1);
    },

    webtitleToslug() {
      const temp = this.StringToSlug(this.form.web_name);
      this.form.slug = temp;
      return temp;
    },
  },
  created() {
    this.LoadProduct();
  },
  methods: {
    validationForm() {
      this.$refs.editProduct.validate().then((success) => {
        if (success) {
          this.EditProduct();
        }
      });
    },
    LoadProduct() {
      if (this.product_id) {
        this.$http({
          method: "GET",
          url: `/product/` + this.product_id,
        })
          .then((x) => {
            this.form = x.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    EditProduct() {
      this.$http({
        method: "PUT",
        url: `/product/` + this.product_id,
        data: this.form,
      })
        .then((x) => {
          this.$root.$emit("edit-product-complete");
          this.showToast("success", "CheckIcon");
          this.$refs["edit-product-modal"].hide();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "แก้ไขข้อมูลอุปกรณ์",
          icon,
          text: "แก้ไขข้อมูลอุปกรณ์เรียบร้อยแล้ว",
          variant,
        },
      });
    },

    StringToSlug(value) {
      value = value.toLowerCase();
      // faz as substituições dos acentos
      value = value.replace(/[á|ã|â|à]/gi, "a");
      value = value.replace(/[é|ê|è]/gi, "e");
      value = value.replace(/[í|ì|î]/gi, "i");
      value = value.replace(/[õ|ò|ó|ô]/gi, "o");
      value = value.replace(/[ú|ù|û]/gi, "u");
      value = value.replace(/[ç]/gi, "c");
      value = value.replace(/[ñ]/gi, "n");
      value = value.replace(/[á|ã|â]/gi, "a");
      // faz a substituição dos espaços e outros caracteres por - (hífen)
      value = value.replace(/\W/gi, "-");
      // remove - (hífen) duplicados
      value = value.replace(/(\-)\1+/gi, "-");
      return value;
    },
  },
};
</script>
