<template>
  <div>
    <div class="d-flex justify-content-between">
      <div><h3>รายการอุปกรณ์</h3></div>
      <div class="d-flex justify-content-between">
        <b-button
          class="mb-1"
          variant="relief-success"
          size="sm"
          v-b-modal.modal-add-product-sub
        >
          <feather-icon icon="PlusIcon" /> เพิ่มอุปกรณ์
        </b-button>
      </div>
    </div>
    <b-card no-body>
      <b-table responsive="sm" :items="items" :fields="fields">
        <template #cell(serial)="data">
          {{ data.item.serial }}
        </template>

        <template #cell(number)="data">
          {{ data.item.number }}
        </template>

        <template #cell(name)="data">
          {{ data.value.first + " - " + data.value.last }}
        </template>

        <template #cell(purchase_duration)="data">
          {{ data.item.purchase_duration }} วัน
        </template>

        <template #cell(product_status)="data">
          <b-badge pill :variant="statusVariant(data.item.product_status)">
            {{ data.item.product_status }}
          </b-badge>
        </template>

        <template #cell(action)="data">
          <b-button
            size="sm"
            variant="relief-warning"
            @click="selected_sub_product = data.item"
            class="mr-1"
            v-b-modal.modal-edit-product-sub
          >
            Edit
          </b-button>
          <b-button
            size="sm"
            variant="relief-danger"
            @click="showDeleteConfirmBox(data.item._id)"
          >
            Delete
          </b-button>
        </template>
      </b-table>
    </b-card>
    <product-sub-add />
    <product-sub-edit :form="selected_sub_product" />
  </div>
</template>

<script>
import { BCard, BCardText, BBadge, BButton } from "bootstrap-vue";
import { BTable } from "bootstrap-vue";
import ProductSubAdd from "./ProductSub-Modal.vue";
import ProductSubEdit from "./ProductSub-Edit-Modal.vue";

export default {
  props: ["info"],
  watch: {},
  components: {
    BCard,
    BCardText,
    BTable,
    BBadge,
    BButton,
    ProductSubAdd,
    ProductSubEdit,
  },
  data() {
    return {
      product_id: this.$route.params.id,
      selected_sub_product: {},
      fields: [
        { key: "serial", label: "Serial" },
        { key: "number", label: "ตัวที่" },
        { key: "purchase_price", label: "ราคาซื้อ" },
        { key: "purchase_price", label: "ราคาซื้อ" },
        { key: "product_status", label: "สถานะ" },
        { key: "purchase_duration", label: "อายุ" },
        { key: "action", label: "Action" },
      ],
      // items: [],
    };
  },
  methods: {
    showDeleteConfirmBox(product_sub_id) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("ลบอุปกรณ์ชิ้นนี้", {
          title: "ลบอุปกรณ์",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.$http({
              method: "DELETE",
              url: `/product-sub/${product_sub_id}`,
            }).then(async (x) => {
              await this.$root.$emit("edit-product-complete");
            });
          }
        });
    },
  },
  created() {},
  computed: {
    items() {
      return this.info.sub_product;
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        ready: "success",
        repair: "info",
        lost: "danger",
        on_case: "warning",
        disable: "info",
        sold: "danger",
        /* eslint-enable key-spacing */
      };

      return (web_status) => statusColor[web_status];
    },
  },
};
</script>

<style></style>
