var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('h4',[_vm._v("ProductDescription")]),_c('b-form-textarea',{staticClass:"my-1",attrs:{"id":"textarea-default","placeholder":"Textarea","rows":"3"},model:{value:(_vm.info.short_description),callback:function ($$v) {_vm.$set(_vm.info, "short_description", $$v)},expression:"info.short_description"}}),_c('h4',[_vm._v("Long Description")]),_c('quill-editor',{staticClass:"my-1",attrs:{"options":_vm.snowOption},model:{value:(_vm.info.long_description),callback:function ($$v) {_vm.$set(_vm.info, "long_description", $$v)},expression:"info.long_description"}}),_c('h4',[_vm._v("Feature")]),_c('b-form-tags',{attrs:{"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-input-group',{attrs:{"aria-controls":"my-custom-tags-list"}},[_c('input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"placeholder":"New tag - Press enter to add"}},'input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return addTag()}}},[_vm._v(" Add ")])],1)],1),_c('ul',{staticClass:"list-unstyled d-inline-flex flex-wrap mb-0",attrs:{"id":"my-custom-tags-list","aria-live":"polite","aria-atomic":"false","aria-relevant":"additions removals"}},_vm._l((tags),function(tag){return _c('b-card',{key:tag,staticClass:"shadow-none border mt-1 mr-1 mb-0",attrs:{"id":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_"),"tag":"li","body-class":"py-1 pr-2 text-nowrap"}},[_c('strong',[_vm._v(_vm._s(tag))]),_c('b-button',{staticClass:"py-0",attrs:{"variant":"link","size":"sm","aria-controls":("my-custom-tags-tag_" + (tag.replace(
                /\s/g,
                '_'
              )) + "_")},on:{"click":function($event){return removeTag(tag)}}},[_vm._v(" remove ")])],1)}),1)]}}]),model:{value:(_vm.info.feature),callback:function ($$v) {_vm.$set(_vm.info, "feature", $$v)},expression:"info.feature"}}),_c('h4',[_vm._v("Item Include อุปกรณ์ที่ให้ไปด้วยหน้าเว็บ")]),_c('b-form-tags',{attrs:{"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var tags = ref.tags;
              var inputAttrs = ref.inputAttrs;
              var inputHandlers = ref.inputHandlers;
              var addTag = ref.addTag;
              var removeTag = ref.removeTag;
return [_c('b-input-group',{attrs:{"aria-controls":"my-custom-tags-list"}},[_c('input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"placeholder":"New tag - Press enter to add"}},'input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return addTag()}}},[_vm._v(" Add ")])],1)],1),_c('ul',{staticClass:"list-unstyled d-inline-flex flex-wrap mb-0",attrs:{"id":"my-custom-tags-list","aria-live":"polite","aria-atomic":"false","aria-relevant":"additions removals"}},_vm._l((tags),function(tag){return _c('b-card',{key:tag,staticClass:"shadow-none border mt-1 mr-1 mb-0",attrs:{"id":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_"),"tag":"li","body-class":"py-1 pr-2 text-nowrap"}},[_c('strong',[_vm._v(_vm._s(tag))]),_c('b-button',{staticClass:"py-0",attrs:{"variant":"link","size":"sm","aria-controls":("my-custom-tags-tag_" + (tag.replace(
                /\s/g,
                '_'
              )) + "_")},on:{"click":function($event){return removeTag(tag)}}},[_vm._v(" remove ")])],1)}),1)]}}]),model:{value:(_vm.info.web_items_include),callback:function ($$v) {_vm.$set(_vm.info, "web_items_include", $$v)},expression:"info.web_items_include"}}),_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":_vm.update}},[_vm._v("Save")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }