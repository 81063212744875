<template>
  <div id="app">
    <b-card>
      <b-row>
        <b-col sm="4" md="2">
          <b-card>
            <vue-dropzone
              id="dropzone"
              ref="imgDropzone"
              :options="dropzoneOption"
              @vdropzone-complete="afterComplete"
            />
          </b-card>
        </b-col>
        <b-col sm="4" md="2" v-for="image in info.web_image" :key="image._id">
          <b-card :border-variant="ismain(image.ismain)">
            <b-img :src="image.url" fluid alt="Responsive image" />
            <b-row align-h="between">
              <b-col cols="4">
                <!-- <b-badge variant="primary" v-if="image.ismain">
                    ภาพหลัก
                </b-badge> -->
              </b-col>
              <b-col class="text-right">
                <b-dropdown
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  split
                  text="Option"
                  right
                  variant="secondary"
                  class="align-right"
                >
                  <b-dropdown-item
                    variant="success"
                    @click="setMain(image._id)"
                  >
                    ตั้งเป็นภาพหลัก
                  </b-dropdown-item>
                  <b-dropdown-item
                    variant="danger"
                    @click="deleteImage(image._id)"
                  >
                    ลบ
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BFormFile,
  BButton,
  BRow,
  BCol,
  BCard,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BImg,
} from "bootstrap-vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Ripple from "vue-ripple-directive";
import firebase from "firebase/app";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";

let uuid = require("uuid");

export default {
  components: {
    BFormFile,
    BButton,
    BRow,
    BCol,
    vueDropzone: vue2Dropzone,
    BCard,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: ["info"],
  data() {
    return {
      file: null,
      images: [],
      dropzoneOption: {
        url: "https://httpbin.org/post",
        // thumbnailWidth:250,
        // thumbnailHeight:250,
        addRemoveLinks: false,
        acceptedFiles: ".jpg,.jpeg,.png,.webp",
      },
      product_id: this.$route.params.id,
    };
  },
  created() {},
  methods: {
    async afterComplete(file) {
      try {
        const imageName = uuid.v1();
        var metaData = {
          contentType: "image/png",
        };

        const storage = getStorage();
        const storageRef = ref(storage, `web_product_image/${imageName}.png`);
        const uploadTask = uploadBytesResumable(storageRef, file, metaData);

        uploadTask.then(async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          const meta = await getMetadata(uploadTask.snapshot.ref);

          this.$http({
            method: "POST",
            url: `/product/image/` + this.product_id,
            data: {
              url: url,
              img_path: meta.fulPath,
            },
          }).then((x) => {
            this.$root.$emit("edit-product-complete");
          });
        });
        this.$refs.imgDropzone.removeFile(file);
      } catch (error) {
        console.log(error);
      }
    },
    loadImage() {
      this.$http({
        method: "GET",
        url: `/product/image/` + this.product_id,
      }).then((x) => {
        this.rows = x.data;
      });
    },
    setMain(id) {
      this.$http({
        method: "POST",
        url: `/product/image/setmain/` + this.product_id,
        data: {
          img_id: id,
        },
      })
        .then((x) => {
          this.$root.$emit("edit-product-complete");
        })
        .catch((e) => {
          console.log("image upload Error", e);
        });
    },
    deleteImage(id) {
      this.$http({
        method: "DELETE",
        url: `/product/image/delete/` + this.product_id,
        data: {
          img_id: id,
        },
      })
        .then((x) => {
          this.$root.$emit("edit-product-complete");
        })
        .catch((e) => {
          console.log("image upload Error", e);
        });
    },
    ismain(xx) {
      if (xx) {
        return "success";
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
/* .img-div{
display: flex;
margin: 25px;
} */

img {
  max-width: 250px;
  margin-bottom: 15px;
}
</style>

<!-- <b-col cols='5'>
    <b-form-file
        v-model="file"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
    />
    <b-button
        variant="primary"
        >
            Upload
    </b-button>
</b-col> -->
