<template>
  <div>
    <b-tabs v-model="activeTabIndex">
      <b-tab title="อุปกรณ์">
        <product-in-kit v-if="info.is_kit" :info="info" />
        <go-pro-option v-if="info.is_gopro" :info="info"></go-pro-option>
        <product-info :info="info" />
        <div class="row">
          <div class="col"><accessories-include /></div>
          <div class="col">
            <product-sub :info="info" />
          </div>
        </div>
      </b-tab>
      <b-tab title="ข้อมูลหน้าเว็บ">
        <h3>{{ info.name }}</h3>
        <attribute-info :info="info" />
        <image-upload :info="info"></image-upload>
        <product-description :info="info"></product-description>
      </b-tab>

      <b-tab title="สถิติ" v-if="ROLE === 'owner'">
        <product-analytic :info="info"></product-analytic>
      </b-tab>
    </b-tabs>

    <edit-product-modal :product_id="product_id" />
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BCard, BCardText, BRow, BCol, BTab, BTabs } from "bootstrap-vue";
import ProductSub from "./component/ProductSub.vue";
import ProductInfo from "./component/ProductInfo.vue";
import EditProductModal from "./component/EditProductModal.vue";
import ImageUpload from "./component/ImageUpload.vue";
import ProductDescription from "./component/ProductDescription.vue";
import GoProOption from "./component/GoProOption.vue";
import AccessoriesInclude from "./component/AccessoriesInclude.vue";
import AttributeInfo from "./component/AttributeInfo.vue";
import ProductInKit from "./component/ProductInKit.vue";
import ProductAnalytic from "./component/ProductAnalytic.vue";
import { getUserRole } from "@/auth/utils";

export default {
  components: {
    BCard,
    BCardText,
    ProductSub,
    ProductInfo,
    EditProductModal,
    ImageUpload,
    BRow,
    BCol,
    ProductDescription,
    GoProOption,
    AccessoriesInclude,
    AttributeInfo,
    ProductInKit,
    BTab,
    BTabs,
    ProductAnalytic,
  },
  data() {
    return {
      product_id: this.$route.params.id,
      info: {},
      activeTabIndex: 0,
      ROLE: "staff",
    };
  },
  mounted() {
    this.$root.$on("edit-product-complete", async () => {
      await this.loadData();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Update",
          icon: "CheckIcon",
          // text: res.success ? "เข้าสู่ระบบสำเร็จ" : res.message,
          variant: "success",
        },
      });
    });
  },
  created() {
    this.ROLE = getUserRole();
    this.loadData();
  },
  methods: {
    loadData() {
      this.$http({
        method: "GET",
        url: `/product/` + this.$route.params.id,
      }).then((x) => {
        this.info = x.data.data;
      });
    },
  },
};
</script>
