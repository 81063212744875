-
<template>
  <div>
    <b-card>
      <b-form-group>
        <h5>แสดงหน้าเว็บ</h5>
        <div class="demo-inline-spacing">
          <b-form-radio v-model="scope" name="some-radios3" value="month">
            Month
          </b-form-radio>
          <b-form-radio v-model="scope" name="some-radios3" value="week">
            Week
          </b-form-radio>
        </div>
      </b-form-group>
      <b-form-group>
        <h5>MODE</h5>
        <div class="demo-inline-spacing">
          <b-form-radio
            @change="clear()"
            v-model="section"
            name="some-radios31"
            value="sale"
          >
            ยอดขาย
          </b-form-radio>
          <b-form-radio
            @change="clear()"
            v-model="section"
            name="some-radios31"
            value="load"
          >
            Load
          </b-form-radio>
        </div>
      </b-form-group>
      <div class="row">
        <div class="col text-right">
          <b-button
            variant="relief-success"
            class="btn-icon"
            @click="loadData()"
          >
            <b-spinner small v-if="is_loading" /> Search</b-button
          >
        </div>
      </div>
    </b-card>
    <!--  -->
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th v-for="(x, index) in table_head" :key="index">
              <h4>ตัวที่ {{ x.number }}</h4>
              {{ x.serial }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(x, index) in row_data" :key="index">
            <th>{{ x.display_label }}</th>
            <th v-for="(zz, index) in table_head" :key="index">
              <span v-if="section === 'load'">
                {{ x[zz.sub_id] | pct }}
              </span>
              <span v-else>
                {{ x[zz.sub_id] | number }}
              </span>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BFormRadio,
  BButton,
  BSpinner,
  BFormGroup,
} from "bootstrap-vue";

export default {
  components: {
    BFormGroup,
    BCard,
    BFormRadio,
    BButton,
    BSpinner,
  },
  props: ["info"],
  created() {
    // this.loadData();
  },
  data() {
    return {
      table_head: {},
      row_data: [],
      scope: "month",
      is_loading: false,
      section: "load", //load , sale
    };
  },
  methods: {
    clear() {
      this.table_head = {};
      this.row_data = [];
    },
    loadData() {
      if (this.section === "sale") {
        this.loadSaleData();
      } else if (this.section === "load") {
        this.loadLoadData();
      }
    },
    loadSaleData() {
      this.$http({
        method: "POST",
        url: `product/rental-history`,
        data: {
          product_id: this.$route.params.id,
          scope: this.scope, /// week,month
        },
      }).then((x) => {
        this.table_head = x.data.data.header;
        this.row_data = x.data.data.row_data;
      });
    },
    loadLoadData() {
      this.$http({
        method: "POST",
        url: `product/item-load`,
        data: {
          product_id: this.$route.params.id,
          scope: this.scope, /// week,month
        },
      }).then((x) => {
        this.table_head = x.data.data.header;
        this.row_data = x.data.data.row_data;
        console.log("info", x.data.data);
      });
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    pct: function (x) {
      if (x != null) {
        if (x > 0) {
          return parseFloat(x).toFixed(2) + "%";
        } else {
          return "";
        }
      } else {
        return x;
      }
    },
  },
};
</script>
