<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div class="d-flex justify-content-between">
            <div>
              <h3>
                {{ info.name }}
                <b-badge class="ml-1" variant="success" v-if="info.is_kit"
                  >อุปกรณ์จัดชุด</b-badge
                >
              </h3>
            </div>
            <div>
              <b-button
                class="ml-1"
                size="sm"
                variant="relief-warning"
                v-b-modal.edit-product-modal
                >Edit</b-button
              >
              <b-button
                class="ml-1"
                size="sm"
                variant="relief-danger"
                @click="showDeleteConfirmBox(info._id)"
                >Delete</b-button
              >
            </div>
          </div>
          <br />
          <b-row>
            <b-col md="6">
              <b-list-group flush>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>ID อุปกรณ์</b>
                    {{ info._id }}
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>ชื่อในระบบ</b>
                    {{ info.name }}
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>ชื่อหน้าเว็บ</b>
                    {{ info.web_name }}
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>ชื่อในสัญญาเช่า</b>
                    {{ info.lease_name }}
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>url-slug</b>
                    {{ info.slug }}
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>Brand</b>
                    <!-- {{ info.categories }} -->
                    <div>
                      {{ info.brand }}
                    </div>
                  </div>
                </b-list-group-item>

                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>Categories</b>
                    <!-- {{ info.categories }} -->
                    <div>
                      <b-badge pill variant="success" class="ml-1">{{
                        info.categories
                      }}</b-badge>
                      <b-badge
                        pill
                        variant="success"
                        class="ml-1"
                        v-if="info.is_gopro"
                        >GoProlineup</b-badge
                      >
                    </div>
                  </div>
                </b-list-group-item>

                <b-list-group-item
                  v-if="info.categories == 'GoPro Accessories'"
                >
                  <div class="d-flex justify-content-between">
                    <b>Accessories ฟรี</b>
                    <div>
                      <b-badge
                        pill
                        variant="success"
                        class="ml-1"
                        v-if="!info.gopro_acc_free"
                        >เช่าเพิ่มคิดเป็นครั้ง</b-badge
                      >
                      <b-badge
                        pill
                        variant="warning"
                        class="ml-1"
                        v-if="info.gopro_acc_free"
                        >เลือกฟรี 1 รายการ</b-badge
                      >
                    </div>
                  </div>
                </b-list-group-item>

                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <div>
                      <b>Tags</b>
                    </div>
                    <div>
                      <b-badge
                        v-for="x in info.tags"
                        :key="x"
                        pill
                        variant="success"
                        class="ml-1"
                      >
                        {{ x }}
                      </b-badge>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col md="6">
              <b-list-group flush>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>ค่าเช่า</b>
                    {{ info.rental_price | number }}
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>ยอดวางประกัน 1</b>
                    {{ info.deposit_1 | number }}
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>ยอดวางประกัน 2</b>
                    {{ info.deposit_2 | number }}
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>ยอดวางประกัน 3</b>
                    {{ info.deposit_3 | number }}
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>ราคาในสัญญาเช่า</b>
                    {{ info.lease_price | number }}
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>แสดงหน้าเว็บ</b>
                    <b-badge pill v-if="info.web_enable" variant="success">
                      แสดงหน้าเว็บ
                    </b-badge>
                    <b-badge pill v-if="!info.web_enable" variant="danger">
                      ไม่แสดงหน้าเว็บ
                    </b-badge>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>เปิดให้เช่า</b>
                    <b-badge
                      pill
                      v-if="info.admin_enable === true"
                      variant="success"
                    >
                      เปิดให้เช่า
                    </b-badge>
                    <b-badge
                      pill
                      v-else-if="info.admin_enable === false"
                      variant="danger"
                    >
                      ยกเลิกให้เช่า
                    </b-badge>
                    <b-badge pill v-else variant="danger">
                      admin_enable ERROR
                    </b-badge>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>จัดส่ง</b>
                    <b-badge pill v-if="info.shipable" variant="success">
                      จัดส่งได้
                    </b-badge>
                    <b-badge pill v-if="!info.shipable" variant="danger">
                      จัดส่งไม่ได้
                    </b-badge>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>คิดค่าเช่า</b>
                    <b-badge pill v-if="info.flat_rate" variant="warning">
                      คิดเป็นครั้ง
                    </b-badge>
                    <b-badge pill v-if="!info.flat_rate" variant="success">
                      ค่าเช่ารายวัน
                    </b-badge>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>GoProlineup</b>
                    <b-badge pill v-if="info.is_gopro" variant="success">
                      Yes
                    </b-badge>
                    <b-badge pill v-if="!info.is_gopro" variant="danger">
                      No
                    </b-badge>
                  </div>
                </b-list-group-item>

                <b-list-group-item>
                  <div class="d-flex justify-content-between">
                    <b>นอกค่าย</b>
                    <b-badge pill v-if="info.is_third_party" variant="success">
                      นอกค่าย
                    </b-badge>
                    <b-badge pill v-if="!info.is_third_party" variant="danger">
                      No
                    </b-badge>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
  BBadge,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
  },
  data() {
    return {};
  },
  props: ["info"],
  created() {},
  methods: {
    showDeleteConfirmBox(product_id) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("ลบอุปกรณ์รุ่นนี้ อุปกรณ์ย่อยทั้งหมดจะถูกลบ", {
          title: "ลบอุปกรณ์",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.$http({
              method: "DELETE",
              url: `/product/${product_id}`,
            })
              .then((x) => {
                this.$router.push({ name: "product-list" });
              })
              .catch((e) => {
                console.log("Catch", e);
              });
          }
        });
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>

<style></style>
