<template>
    <div>
        <b-card>
            <h4>GoPro Accessories Support</h4>
            <b-form-group >
                <b-form-tags
                    v-model="info.item_support"
                    no-outer-focus
                >
                    <template #default="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
                    <b-input-group aria-controls="my-custom-tags-list">
                        <input
                        v-bind="inputAttrs"
                        placeholder="New tag - Press enter to add"
                        class="form-control"
                        v-on="inputHandlers"
                        >
                        <b-input-group-append>
                        <b-button
                            variant="primary"
                            @click="addTag()"
                        >
                            Add
                        </b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <ul
                        id="my-custom-tags-list"
                        class="list-unstyled d-inline-flex flex-wrap mb-0"
                        aria-live="polite"
                        aria-atomic="false"
                        aria-relevant="additions removals"
                    >
                        <b-card
                        v-for="tag in tags"
                        :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                        :key="tag"
                        tag="li"
                        class="shadow-none border mt-1 mr-1 mb-0"
                        body-class="py-1 pr-2 text-nowrap"
                        >
                        <strong>{{ tag }}</strong>
                        <b-button
                            variant="link"
                            size="sm"
                            :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                            class="py-0"
                            @click="removeTag(tag)"
                        >
                            remove
                        </b-button>
                        </b-card>
                    </ul>
                    </template>
                </b-form-tags>
            </b-form-group>
            
            <b-button size="sm" variant="success" @click="update">Save</b-button>
        </b-card>
    </div>
</template>

<script>
import { BCard,BButton,BFormTags,BFormGroup,BFormRadio, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'

export default {
    components:{
        BCard,
        BButton,
        BFormTags,
        BInputGroup,
        BInputGroupAppend,
        BFormGroup,
        BFormRadio
    },
    created() {
    },
    data:()=>({
        form:{},
    }),
    props: ['info'],
    methods:{
        update(){
            this.form.item_support = this.info.item_support

            this.$http({
                method: 'PUT',
                url: `/product/`+this.$route.params.id,
                data: this.form
            }).then(x => {
                this.$root.$emit('edit-product-complete')
            }).catch(e=>{
                console.log('image upload Error',e)
            })
        }
    }
}
</script>