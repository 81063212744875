<template>
  <div>
    <b-modal
      id="modal-edit-product-sub"
      ref="modal-edit-product-sub"
      title="แก้ไขอุปกรณ์ย่อย"
      no-close-on-backdrop
      size="lg"
      hide-footer
    >
      <validation-observer ref="editProductSub">
        <b-form>
          <!-- Serial -->
          <b-form-group label="Serial Number">
            <validation-provider
              #default="{ errors }"
              name="Serial"
              rules="required"
            >
              <b-form-input
                v-model="form.serial"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ตัวที่ -->
          <b-form-group label="ตัวที่">
            <validation-provider
              #default="{ errors }"
              name="Number"
              rules="required"
            >
              <b-form-input
                v-model="form.number"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- วันที่ซื้อ -->
          <b-form-group label="วันที่ซื้อ">
            <validation-provider
              #default="{ errors }"
              name="PurchaseDate"
              rules="required"
            >
              <date-picker
                v-model="form.purchase_date"
                :masks="{ input: 'D/M/YYYY' }"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <b-form-group>
                    <!-- :mode="date-time" -->
                    <b-form-input
                      class="bg-white border px-2 py-1 rounded"
                      :value="inputValue"
                      @click="togglePopover()"
                    />
                  </b-form-group>
                </template>
              </date-picker>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- วันที่หมดประกัน -->
          <b-form-group label="วันที่หมดประกัน">
            <validation-provider #default="{ errors }" name="warranty_end">
              <date-picker
                v-model="form.warranty_end"
                :masks="{ input: 'D/M/YYYY' }"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <b-form-group>
                    <!-- :mode="date-time" -->
                    <b-form-input
                      class="bg-white border px-2 py-1 rounded"
                      :value="inputValue"
                      @click="togglePopover()"
                    />
                  </b-form-group>
                </template>
              </date-picker>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- วันที่ขายออก -->
          <b-form-group label="ประมาณวันที่ขายออก">
            <validation-provider #default="{ errors }" name="sell date">
              <date-picker
                v-model="form.estimate_sale_date"
                :masks="{ input: 'D/M/YYYY' }"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <b-form-group>
                    <!-- :mode="date-time" -->
                    <b-form-input
                      class="bg-white border px-2 py-1 rounded"
                      :value="inputValue"
                      @click="togglePopover()"
                    />
                  </b-form-group>
                </template>
              </date-picker>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ราคาซื้อ -->
          <b-form-group label="ราคาซื้อ">
            <validation-provider
              #default="{ errors }"
              name="Purchase_Price"
              rules="required"
            >
              <b-form-input
                v-model="form.purchase_price"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ราคาขายออก -->
          <b-form-group label="ราคาขายออก">
            <validation-provider
              #default="{ errors }"
              name="estimate_sale_price"
            >
              <b-form-input
                v-model="form.estimate_sale_price"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ของใหม่ / มือ 2 -->
          <b-form-group>
            <h5>ของใหม่ / มือสอง</h5>
            <div class="demo-inline-spacing" v-if="form">
              <b-form-radio-group v-model="form.purchase_condition">
                <b-form-radio name="some-radio" value="new"> New </b-form-radio>
                <b-form-radio name="some-radio" value="used">
                  Used
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </b-form-group>
          <!-- สถานะ -->
          <b-form-group>
            <h5>สถานะการใช้เช่า</h5>
            <div class="demo-inline-spacing" v-if="form">
              <b-form-radio
                v-model="form.product_status"
                name="some-radios2"
                value="ready"
              >
                พร้อมให้เช่า
              </b-form-radio>
              <b-form-radio
                v-model="form.product_status"
                name="some-radios2"
                value="repair"
              >
                ส่งซ่อม
              </b-form-radio>
              <b-form-radio
                v-model="form.product_status"
                name="some-radios2"
                value="lost"
              >
                หาย
              </b-form-radio>
              <b-form-radio
                v-model="form.product_status"
                name="some-radios2"
                value="disable"
              >
                งดให้เช่า
              </b-form-radio>
              <b-form-radio
                v-model="form.product_status"
                name="some-radios2"
                value="sold"
              >
                ขายแล้ว
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- retro_id -->
          <b-form-group label="retro_id (ตัว [เยอะ])">
            <b-form-input v-model="form.retro_id" />
          </b-form-group>

          <!-- retro_product_id -->
          <b-form-group label="retro_product_id (รุ่น [น้อย])">
            <b-form-input v-model="form.retro_product_id" />
          </b-form-group>

          <br />
          <!-- @click="validationForm" -->
          <div class="text-right justify">
            <b-button variant="relief-secondary" class="mr-1" @click="cancel">
              Cancel
            </b-button>
            <b-button variant="relief-success" @click.prevent="validationForm">
              แก้ไขอุปกรณ์
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadioGroup,
    BFormRadio,
    DatePicker,
  },
  created() {},
  data() {
    return {};
  },
  props: ["form"],
  methods: {
    validationForm() {
      this.$refs.editProductSub.validate().then((success) => {
        if (success) {
          this.$http({
            method: "PUT",
            url: `/product-sub/` + this.form._id,
            data: this.form,
          }).then(async (x) => {
            this.$refs["modal-edit-product-sub"].hide();
            await this.$root.$emit("edit-product-complete");
          });
        }
      });
    },
    cancel() {
      this.$refs["modal-edit-product-sub"].hide();
    },
  },
};
</script>
<style>
.bg-white {
  background-color: #283046 !important;
}
</style>
